import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from '@kiosk/router/ProtectedRoute';
import RestrictedRoute from '@kiosk/router/RestrictedRoute';
import { ACCOUNT_MANAGER_ROLE, ADMIN_ROLE } from '@kiosk/config/Roles';
import getQueryParam from '@kiosk/utils/getQueryParam';
import AdminConfigModules from '@kiosk/pages/AdminConfigModules';
import SiteDesigner from '@kiosk/pages/SiteDesigner';
import ListViewConfigPage from '@kiosk/pages/SiteDesigner/ListViewConfigPage';
import RecordViewConfigPage from '@kiosk/pages/SiteDesigner/RecordViewConfigPage';
const AdminConfig = lazy(() => import('@kiosk/pages/AdminConfig'));
const AdminConfigFeatures = lazy(() =>
  import('@kiosk/pages/AdminConfigFeatures')
);
const UserRecord = lazy(() => import('@kiosk/pages/UserRecord'));
const InviteUsers = lazy(() => import('@kiosk/pages/InviteUsers'));
const Login = lazy(() => import('@kiosk/pages/Login'));
const Logout = lazy(() => import('@kiosk/pages/Logout'));
const AcceptInvitationOrPwReset = lazy(() =>
  import('@kiosk/pages/AcceptInvitationOrPwReset')
);
const CreateRecord = lazy(() => import('@kiosk/pages/CreateRecord'));
const List = lazy(() => import('@kiosk/pages/List'));
const HomePage = lazy(() => import('@kiosk/pages/HomePage'));
const Record = lazy(() => import('@kiosk/pages/Record'));
const ManageUsers = lazy(() => import('@kiosk/pages/ManageUsers'));
const MyProfile = lazy(() => import('@kiosk/pages/MyProfile'));
const HttpErrorPage = lazy(() =>
  import('@kiosk/pages/HttpErrorFallback/HttpErrorPage')
);

const KioskRouter = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <ProtectedRoute>
            <Suspense>
              <HomePage />
            </Suspense>
          </ProtectedRoute>
        }
      />
      {/** List View Routes **/}
      <Route path=':module'>
        <Route
          index
          element={
            <ProtectedRoute>
              <Suspense>
                <List />
              </Suspense>
            </ProtectedRoute>
          }
        />
        <Route
          path=':recordId'
          element={
            <ProtectedRoute>
              <Suspense>
                <Record />
              </Suspense>
            </ProtectedRoute>
          }
        />
        <Route
          path='create'
          element={
            <ProtectedRoute>
              <Suspense>
                <CreateRecord />
              </Suspense>
            </ProtectedRoute>
          }
        />
      </Route>
      {/** Users Routes **/}
      <Route path='users'>
        <Route
          index
          element={
            <RestrictedRoute allowedRoles={[ACCOUNT_MANAGER_ROLE, ADMIN_ROLE]}>
              <Suspense>
                <ManageUsers />
              </Suspense>
            </RestrictedRoute>
          }
        />
        <Route
          path=':uid'
          element={
            <RestrictedRoute allowedRoles={[ACCOUNT_MANAGER_ROLE, ADMIN_ROLE]}>
              <Suspense>
                <UserRecord />
              </Suspense>
            </RestrictedRoute>
          }
        />
        <Route
          path='invite'
          element={
            <RestrictedRoute allowedRoles={[ACCOUNT_MANAGER_ROLE, ADMIN_ROLE]}>
              <Suspense>
                <InviteUsers />
              </Suspense>
            </RestrictedRoute>
          }
        />
      </Route>
      <Route
        path='profile'
        element={
          <ProtectedRoute>
            <Suspense>
              <MyProfile />
            </Suspense>
          </ProtectedRoute>
        }
      />
      {/** Admin Routes */}
      <Route path='admin'>
        {/* For now we just want to redirect immediately to the theme config
          page if navigated to manually. This leave room for an entire "admin" page */}
        <Route
          index
          element={
            <RestrictedRoute allowedRoles={[ADMIN_ROLE]}>
              <Suspense>
                <Navigate to='/admin/config/theme' replace />
              </Suspense>
            </RestrictedRoute>
          }
        />
        <Route path='config'>
          {/* For now we just want to redirect immediately to the theme config
          page if navigated to manually. This leave roome for a base "configuration" page */}
          <Route
            index
            element={
              <RestrictedRoute allowedRoles={[ADMIN_ROLE]}>
                <Suspense>
                  <Navigate to='/admin/config/theme' replace />
                </Suspense>
              </RestrictedRoute>
            }
          />
          <Route path='site-designer'>
            <Route
              index
              element={
                <RestrictedRoute allowedRoles={[ADMIN_ROLE]}>
                  <Suspense>
                    <SiteDesigner />
                  </Suspense>
                </RestrictedRoute>
              }
            />
            <Route path='module-layouts'>
              <Route
                index
                element={
                  <RestrictedRoute allowedRoles={[ADMIN_ROLE]}>
                    <Suspense>
                      <SiteDesigner />
                    </Suspense>
                  </RestrictedRoute>
                }
              />
              <Route
                path='record-view/:module'
                element={
                  <RestrictedRoute allowedRoles={[ADMIN_ROLE]}>
                    <Suspense>
                      <RecordViewConfigPage />
                    </Suspense>
                  </RestrictedRoute>
                }
              />
              <Route
                path='list-view/:module'
                element={
                  <RestrictedRoute allowedRoles={[ADMIN_ROLE]}>
                    <Suspense>
                      <ListViewConfigPage />
                    </Suspense>
                  </RestrictedRoute>
                }
              />
            </Route>
          </Route>
          <Route
            path='theme'
            element={
              <RestrictedRoute allowedRoles={[ADMIN_ROLE]}>
                <Suspense>
                  <AdminConfig />
                </Suspense>
              </RestrictedRoute>
            }
          />
          <Route
            path='features'
            element={
              <RestrictedRoute allowedRoles={[ADMIN_ROLE]}>
                <Suspense>
                  <AdminConfigFeatures />
                </Suspense>
              </RestrictedRoute>
            }
          />
          <Route
            path='modules'
            element={
              <RestrictedRoute allowedRoles={[ADMIN_ROLE]}>
                <Suspense>
                  <AdminConfigModules />
                </Suspense>
              </RestrictedRoute>
            }
          />
        </Route>
      </Route>
      {/** Auth Routes **/}
      <Route
        path='login'
        element={
          <Suspense>
            <Login />
          </Suspense>
        }
      />
      <Route
        path='logout'
        element={
          <ProtectedRoute>
            <Suspense>
              <Logout />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path='login/invitation'
        element={
          <Suspense>
            <AcceptInvitationOrPwReset />
          </Suspense>
        }
      />
      <Route
        path='error'
        element={
          <Suspense>
            <HttpErrorPage
              title={getQueryParam('error')}
              description={getQueryParam('error_description')}
            />
          </Suspense>
        }
      />
      <Route
        path='*'
        element={
          <Suspense>
            <HttpErrorPage code={404} />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default KioskRouter;
