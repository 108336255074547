import { Icon, Tooltip } from '@mui/material';

/**
 * A modified MUI-based component for rendering SugarIcons.
 * @param name Name of the icons in the font
 * @param size Abbreviation for the using the sizing helper class (lg, 16, 32, etc)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SugarIcon = ({
  name,
  size = '',
  tooltipText = null,
  tooltipPlacement = 'right',
  ...props
}) => {
  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} placement={tooltipPlacement}>
        <Icon
          baseClassName='sicon'
          className={`sicon-${name}${size ? ` sicon-${size}` : ''}`}
          fontSize=''
          {...props}
        />
      </Tooltip>
    );
  } else {
    return (
      <Icon
        baseClassName='sicon'
        className={`sicon-${name}${size ? ` sicon-${size}` : ''}`}
        fontSize=''
        {...props}
      />
    )
  }
};

export default SugarIcon;
