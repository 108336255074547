import { Box } from '@mui/material';
import SiteDesignerTopBar from '@kiosk/components/SiteDesigner/SiteDesignerTopBar';

const ListViewConfigPage = () => {
  return (
    <Box
      sx={{
        fontFamily: 'Inter',
        height: '100vh',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <SiteDesignerTopBar
        background='linear-gradient(0deg, #7C3BED 0.32%, #3B58CA 100.13%)'
        boxShadow='0px 3px 4px 0px rgba(0, 0, 0, 0.25)'
      />
    </Box>
  );
};

export default ListViewConfigPage;
