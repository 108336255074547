import localization from '@kiosk/i18n/localization';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizedString } from '@kiosk/components/utils/LocalizedString';
import {
  ColorBaseGray300,
  ColorBaseGray700,
  ColorBaseViolet600,
  ColorBaseViolet800,
  ColorBaseWhite,
} from '@kiosk/maple-syrup/build/color/js/sugarColorPalette';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { moduleLayoutsDefs } from '@kiosk/metadata/AdminConfig/SiteDesigner/moduleLayoutsMetadata';

const ModuleLayoutsContent = () => {
  const navigate = useNavigate();

  const queryInput = useRef();
  const [modules, setModules] = useState(moduleLayoutsDefs); // Example fields data
  const [isActiveSearch, setIsActiveSearch] = useState(false);

  const handleSearch = (e) => {
    if (e.target.value === ' ') {
      e.target.value = '';
    }
    const queryText = queryInput.current.value;
    if (queryText === '') {
      setIsActiveSearch(false);
    } else {
      setIsActiveSearch(true);
    }
    if (queryText.length >= 3) {
      setModules(
        moduleLayoutsDefs.filter((item) =>
          item.moduleName.includes(queryText.toLowerCase())
        )
      );
      return;
    }
    setModules(moduleLayoutsDefs);
  };

  return (
    <Box
      sx={{
        marginTop: '2.1875rem',
        height: '100%',
        width: '65%',
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography
          sx={{
            color: ColorBaseWhite,
            wordWrap: 'break-word',
            fontWeight: '400',
            fontSize: '2.125rem',
            fontStyle: 'normal',
          }}
          component='h4'>
          {localization.get('LBL_KIOSK_MODULE_LAYOUTS', 'SiteDesigner')}
        </Typography>
        <TextField
          variant='outlined'
          sx={{
            height: '2.5rem',
            width: '18.75rem',
            color: ColorBaseGray300,
            '& .MuiOutlinedInput-root': {
              paddingRight: '1.25rem',
              '& .MuiOutlinedInput-input': {
                paddingLeft: '1.25rem',
              },
              '& fieldset': {
                borderColor: ColorBaseGray300,
              },
              '&:hover fieldset': {
                borderColor: ColorBaseGray300,
              },
              '&.Mui-focused fieldset': {
                borderColor: ColorBaseGray300,
              },
              '&.search-clear': {
                color: ColorBaseGray300,
              },
            },
          }}
          id='search'
          type='search'
          inputRef={queryInput}
          onChange={handleSearch}
          onKeyDown={handleSearch}
          placeholder={localization.get('LBL_KIOSK_FILTER_SEARCH')}
          inputProps={{ 'aria-label': 'Search', color: ColorBaseWhite }}
          InputProps={{
            sx: {
              color: ColorBaseWhite,
              borderRadius: '2em',
            },
            endAdornment: !isActiveSearch ? (
              <InputAdornment position='end'>
                <IconButton
                  alt='Search Icon'
                  aria-label='Search Icon'
                  sx={{ mr: -1.5 }}
                  onClick={handleSearch}
                  edge='end'>
                  <SearchIcon sx={{ color: ColorBaseWhite }} />
                </IconButton>
              </InputAdornment>
            ) : (
              <InputAdornment
                alt='Clear Search Icon'
                aria-label='Clear Search Icon'
                sx={{ mr: -1.5 }}
                onClick={() => {
                  queryInput.current.value = '';
                  setModules(moduleLayoutsDefs);
                }}
                position='end'>
                <IconButton>
                  <CloseIcon sx={{ color: ColorBaseWhite }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Divider
        sx={{
          backgroundColor: ColorBaseGray300,
          width: '100%',
          marginTop: '1.875rem',
        }}
      />
      <Box
        sx={{
          marginTop: '2.625rem',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}>
        {modules.map((def) => (
          <Card
            key={def.moduleName}
            sx={{
              height: '23.3125rem',
              width: '15rem',
              marginRight: '3.125rem',
              borderRadius: '1rem',
              boxShadow:
                '0rem 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.25)',
              '&:hover': {
                boxShadow:
                  '0rem 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.75)',
              },
            }}>
            <CardMedia
              sx={{
                backgroundColor: def.imgBackgroundColor,
                height: '10.625rem',
              }}
              image={def.img}
            />
            <CardContent
              sx={{
                height: '7.9375rem',
                paddingTop: '1.25rem',
                paddingLeft: '1.125rem',
                paddingRight: '1.125rem',
                paddingBottom: '0.5rem',
              }}>
              <Typography
                sx={{
                  color: ColorBaseGray700,
                  fontSize: '1.375rem',
                  fontWeight: '400',
                }}
                gutterBottom
                variant='h5'
                component='div'>
                <LocalizedString name={def.title} />
              </Typography>
              <Typography
                sx={{
                  color: ColorBaseGray700,
                  fontSize: '0.875rem',
                  letterSpacing: '0rem',
                  fontWeight: '400',
                }}
                variant='body2'>
                <LocalizedString name={def.description} module='SiteDesigner' />
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                height: '4.75rem',
                padding: '0rem',
              }}>
              {def.hasRecordViewButon && (
                <Button
                  sx={{
                    height: '2.25rem',
                    width: '6.6875rem',
                    marginLeft: '0.9375rem',
                    marginRight: 'auto',
                    marginTop: '1.25rem',
                    marginBottom: '1.25rem',
                    fontSize: '0.875rem',
                    wordWrap: 'normal',
                    backgroundColor: ColorBaseViolet600,
                    '&:hover': {
                      backgroundColor: ColorBaseViolet800,
                    },
                  }}
                  variant='contained'
                  onClick={() =>
                    navigate(
                      `/admin/config/site-designer/module-layouts/record-view/${def.moduleName}`
                    )
                  }>
                  <LocalizedString
                    name='LBL_KIOSK_MODULE_LAYOUTS_RECORD_VIEW'
                    module='SiteDesigner'
                  />
                </Button>
              )}
              <Button
                sx={{
                  height: '2.25rem',
                  width: '5.5rem',
                  marginLeft: 'auto',
                  marginRight: '0.9375rem',
                  marginTop: '1.25rem',
                  marginBottom: '1.25rem',
                  fontSize: '0.875rem',
                  backgroundColor: ColorBaseViolet600,
                  '&:hover': {
                    backgroundColor: ColorBaseViolet800,
                  },
                }}
                variant='contained'
                onClick={() =>
                  navigate(
                    `/admin/config/site-designer/module-layouts/record-view/${def.moduleName}`
                  )
                }>
                <LocalizedString
                  name='LBL_KIOSK_MODULE_LAYOUTS_LIST_VIEW'
                  module='SiteDesigner'
                />
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default ModuleLayoutsContent;
