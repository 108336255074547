import { Box } from '@mui/material';
import FieldsPanel from '@kiosk/components/SiteDesigner/FieldsPanel';
import SiteDesignerTopBar from '@kiosk/components/SiteDesigner/SiteDesignerTopBar';
import { useBackground } from '@kiosk/hooks/useBackground';
import { ColorBaseGray100 } from '@kiosk/maple-syrup/build/color/js/sugarColorPalette';

const RecordViewConfigPage = () => {
  useBackground('SiteDesigner', {
    backgroundImage: '',
    backgroundColor: ColorBaseGray100,
  });

  return (
    <>
      <Box
        sx={{
          fontFamily: 'Inter',
          height: '100vh',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <SiteDesignerTopBar
          background='linear-gradient(0deg, #7C3BED 0.32%, #3B58CA 100.13%)'
          boxShadow='0px 3px 4px 0px rgba(0, 0, 0, 0.25)'
        />
        <FieldsPanel />
      </Box>
    </>
  );
};

export default RecordViewConfigPage;
