import CasesImage from '@kiosk/config/images/L2_Cases.svg';
import DocumentsImage from '@kiosk/config/images/L2_Documents.svg';
import {
  ColorBaseOrange400,
  ColorBasePink400,
} from '@kiosk/maple-syrup/build/color/js/sugarColorPalette';

export const moduleLayoutsDefs = [
  {
    img: CasesImage,
    imgBackgroundColor: ColorBaseOrange400,
    title: 'LBL_KIOSK_CASES',
    description: 'LBL_KIOSK_MODULE_LAYOUTS_CASES_DESC',
    moduleName: 'cases',
    hasRecordViewButon: true,
  },
  {
    img: DocumentsImage,
    imgBackgroundColor: ColorBasePink400,
    title: 'LBL_KIOSK_DOCUMENTS',
    description: 'LBL_KIOSK_MODULE_LAYOUTS_DOCUMENTS_DESC',
    moduleName: 'documents',
    hasRecordViewButon: false,
  },
];
