import { cloneElement } from 'react';
import {
  AppBar,
  Box,
  Container,
  Link,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import { ColorGray400 } from '@maple-syrup/build/javascript/sugarColorPalette';
import KioskMenu from '@kiosk/components/KioskMenu';
import KioskAvatarMenu from '@kiosk/components/KioskMenu/KioskAvatarMenu';
import defaultLogo from '@kiosk/config/images/Navbar_logo.jpg';
import { Link as RouterLink } from 'react-router-dom';
import {
  kioskMenuItems,
  kioskAvatarMenuItems,
} from '@kiosk/metadata/kioskMenuMetadata';
import { useSelector } from 'react-redux';
import {
  selectAppConfig,
  selectIsPreviewMode,
} from '@kiosk/redux/slices/appConfig/appConfigSlice';
import { selectUser } from '@kiosk/redux/slices/auth/authSlice';

// Private component used to give an on scroll effect to the navbar
const ElevationScroll = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const Navbar = () => {
  const appConfig = useSelector(selectAppConfig);
  const headerTitle = appConfig?.Theme?.HeaderTitle;
  const logo = appConfig?.Theme?.Logo;

  const user = useSelector(selectUser);
  const isPreview = useSelector(selectIsPreviewMode);

  return (
    <ElevationScroll>
      <AppBar
        position='sticky'
        sx={{
          top: user?.user_roles?.includes('admin') && !isPreview ? '56px' : 0,
          borderBottom: `1px solid ${ColorGray400}`,
        }}
        color='background'
        elevation={0}>
        <Container maxWidth='lg'>
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                '&:hover': { backgroundColor: 'transparent' },
                display: 'flex',
              }}>
              <Link
                component={RouterLink}
                underline='none'
                to='/'
                sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{ height: '32px', width: 'auto' }}
                  component='img'
                  alt='SugarCRM logo'
                  src={logo || defaultLogo}
                />
                <Typography
                  variant='h6'
                  component='h1'
                  sx={{
                    pl: 2,
                    color: 'text.primary',
                  }}>
                  {headerTitle || 'Self-Service Center'}
                </Typography>
              </Link>
            </Box>
            <Box sx={{ mr: 6.25 }}>
              <KioskMenu kioskMenuItems={kioskMenuItems} />
            </Box>
            <Box>
              <KioskAvatarMenu kioskMenuItems={kioskAvatarMenuItems} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

export default Navbar;
