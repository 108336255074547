import { ColorBaseWhite } from '@kiosk/maple-syrup/build/color/js/sugarColorPalette';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import TableChartIcon from '@mui/icons-material/TableChart';

export const breadcrumbsDefs = [
  {
    name: 'site-designer',
    path: '/admin/config/site-designer',
    label: 'LBL_KIOSK_SIDEBAR_SITE_DESIGNER',
    labelModule: 'AdminConfig',
    icon: <FormatPaintIcon color={ColorBaseWhite} fontSize='small' />,
  },
  {
    name: 'module-layouts',
    path: '/admin/config/site-designer/module-layouts',
    label: 'LBL_KIOSK_MODULE_LAYOUTS',
    labelModule: 'SiteDesigner',
    icon: <WidgetsIcon color={ColorBaseWhite} fontSize='small' />,
  },
  {
    name: 'record-view',
    label: 'LBL_KIOSK_MODULE_LAYOUTS_RECORD_VIEW',
    labelModule: 'SiteDesigner',
    icon: <ViewComfyIcon color={ColorBaseWhite} fontSize='small' />,
  },
  {
    name: 'list-view',
    label: 'LBL_KIOSK_MODULE_LAYOUTS_LIST_VIEW',
    labelModule: 'SiteDesigner',
    icon: <TableChartIcon color={ColorBaseWhite} fontSize='small' />,
  },
];
