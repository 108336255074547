import localization from '@kiosk/i18n/localization';
import { Card, CardContent, Box, CardMedia, Typography } from '@mui/material';
import ThemeImage from '@kiosk/config/images/L1_01_Theme.svg';
import LayoutImage from '@kiosk/config/images/L1_02_Layouts.svg';
import { Link } from 'react-router-dom';
import {
  ColorBaseGray700,
  ColorBaseWhite,
} from '@kiosk/maple-syrup/build/color/js/sugarColorPalette';

const SiteDesignerContent = () => {
  return (
    <Box
      sx={{
        marginTop: '2.5rem',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          height: '2.625rem',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          sx={{
            color: ColorBaseWhite,
            wordWrap: 'break-word',
            fontWeight: '400',
            fontSize: '2.125rem',
            fontStyle: 'normal',
          }}
          component='h4'>
          {localization.get('LBL_KIOSK_SITE_DESIGNER_TITLE', 'SiteDesigner')}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '3.625rem',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Link
          style={{
            textDecoration: 'none',
            marginLeft: 'auto',
          }}
          to={'/admin/config/theme'}
          reloadDocument={true}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '1rem',
              marginLeft: 'auto',
              height: '27.5rem',
              width: '21.875rem',
              boxShadow:
                '0rem 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.25)',
              '&:hover': {
                boxShadow:
                  '0rem 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.75)',
              },
            }}>
            <CardMedia
              sx={{
                height: '14.375rem',
                width: '18.375rem',
                marginTop: '3rem',
                marginLeft: '1.75rem',
                marginRight: '1.75rem',
              }}
              component='img'
              image={ThemeImage}
              alt={localization.get(
                'LBL_KIOSK_SITE_DESIGNER_THEME',
                'SiteDesigner'
              )}
            />
            <CardContent
              sx={{
                height: '2.625rem',
                padding: '0rem',
                marginTop: '1rem',
                fontSize: '1.875rem',
                fontWeight: 500,
                color: ColorBaseGray700,
              }}>
              {localization.get(
                'LBL_KIOSK_SITE_DESIGNER_THEME',
                'SiteDesigner'
              )}
            </CardContent>
            <CardContent
              sx={{
                height: '2.5rem',
                width: '17.5rem',
                padding: '0rem',
                marginTop: '1rem',
                marginBottom: '3rem',
                fontSize: '0.875rem',
                textAlign: 'center',
                color: ColorBaseGray700,
              }}>
              {localization.get(
                'LBL_KIOSK_SITE_DESIGNER_THEME_DESCRIPTION',
                'SiteDesigner'
              )}
            </CardContent>
          </Card>
        </Link>
        <Link
          style={{
            textDecoration: 'none',
            marginLeft: '3.125rem',
            marginRight: 'auto',
          }}
          to={'/admin/config/site-designer/module-layouts'}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '1rem',
              height: '27.5rem',
              width: '21.875rem',
              boxShadow:
                '0rem 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.25)',
              '&:hover': {
                boxShadow:
                  '0rem 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.75)',
              },
            }}>
            <CardMedia
              sx={{
                height: '14.375rem',
                width: '18.375rem',
                marginTop: '3rem',
                marginLeft: '1.75rem',
                marginRight: '1.75rem',
              }}
              component='img'
              image={LayoutImage}
              alt={localization.get('LBL_KIOSK_MODULE_LAYOUTS', 'SiteDesigner')}
            />
            <CardContent
              sx={{
                height: '2.625rem',
                padding: '0rem',
                marginTop: '1rem',
                fontSize: '1.875rem',
                fontWeight: 500,
                color: ColorBaseGray700,
              }}>
              {localization.get('LBL_KIOSK_MODULE_LAYOUTS', 'SiteDesigner')}
            </CardContent>
            <CardContent
              sx={{
                height: '2.5rem',
                width: '17.5rem',
                padding: '0rem',
                marginTop: '1rem',
                marginBottom: '3rem',
                fontSize: '0.875rem',
                textAlign: 'center',
                color: ColorBaseGray700,
              }}>
              {localization.get(
                'LBL_KIOSK_MODULE_LAYOUTS_DESCRIPTION',
                'SiteDesigner'
              )}
            </CardContent>
          </Card>
        </Link>
      </Box>
    </Box>
  );
};

export default SiteDesignerContent;
