import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SugarIcon from '@kiosk/components/SugarIcon';
import localization from '@kiosk/i18n/localization';
import {
  ColorBaseGray100,
  ColorBaseGray300,
  ColorBaseGray400,
  ColorBaseGray500,
  ColorBaseGray600,
  ColorBaseViolet700,
  ColorBaseViolet50,
  ColorBaseViolet500,
  ColorBaseWhite,
} from '@kiosk/maple-syrup/build/color/js/sugarColorPalette';
import SiteDesignerSidebar from '../SiteDesignerSidebar';
import { useState } from 'react';

const FieldsPanel = () => {
  const data = [
    {
      fieldName: 'Account Name',
      fieldType: 'relationship',
      tooltipText: 'Relate Field',
    },
    {
      fieldName: 'Assigned To',
      fieldType: 'relationship',
      tooltipText: 'Relate Field',
    },
    {
      fieldName: 'Date Modified',
      fieldType: 'datetime',
      tooltipText: 'Date Field',
    },
    {
      fieldName: 'Deleted',
      fieldType: 'checkbox',
      tooltipText: 'Checkbox Field',
    },
    {
      fieldName: 'Escalated',
      fieldType: 'checkbox',
      tooltipText: 'Checkbox Field',
    },
    {
      fieldName: 'External Source',
      fieldType: 'textfield',
      tooltipText: 'Text Field',
    },
    {
      fieldName: 'External Source ID',
      fieldType: 'textfield',
      tooltipText: 'Text Field',
    },
    {
      fieldName: 'External Source Meta',
      fieldType: 'textarea',
      tooltipText: 'Text Area Field',
    },
    {
      fieldName: 'First Response Actual Time',
      fieldType: 'datetime',
      tooltipText: 'Date Field',
    },
    {
      fieldName: 'First Response Sent',
      fieldType: 'checkbox',
      tooltipText: 'Checkbox Field',
    },
    {
      fieldName: 'First Response SLA Met',
      fieldType: 'dropdown',
      tooltipText: 'Dropdown Field',
    },
    {
      fieldName: 'First Response Target Time',
      fieldType: 'datetime',
      tooltipText: 'Date Field',
    },
    {
      fieldName: 'First Response User',
      fieldType: 'relationship',
      tooltipText: 'Relate Field',
    },
    {
      fieldName: 'First Response Variance Time',
      fieldType: 'decimal',
      tooltipText: 'Number Field',
    },
    {
      fieldName: 'Follow Up Date',
      fieldType: 'datetime',
      tooltipText: 'Date Field',
    },
  ]; // Example fields data

  const [fieldsActive, setFieldsActive] = useState(true);

  return (
    <Box sx={{ display: 'flex', direction: 'row' }}>
      <SiteDesignerSidebar
        fieldsActive={fieldsActive}
        setFieldsActive={setFieldsActive}
      />
      <Box
        elevation={4}
        sx={{
          overflowX: 'hidden',
          position: 'relative',
          backgroundColor: ColorBaseWhite,
          borderRight: '1px solid ' + ColorBaseGray300,
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          borderTopRightRadius: '3px',
          borderBottomRightRadius: '3px',
          marginLeft: 0,
          display: 'block',
          width: fieldsActive ? '18.75rem' : '0',
          transition: 'width 0.5s ease, padding 0.5s ease',
          height: '100vh', // TODO update once we add top Breadcrumbs to Site Designer RecordViewConfig page
          pt: 1.25,
          pl: fieldsActive ? 3.25 : 0,
          pr: fieldsActive ? 1 : 0,
          '&::after': {
            content: '""',
            position: 'absolute',
            width: '1px',
            height: '100%',
            top: 0,
            right: 0,
            zIndex: '-1',
            boxShadow:
              '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);',
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1,
          }}>
          <Typography
            sx={{
              flexGrow: 1,
              fontWeight: '400',
              fontSize: '1.25rem',
              lineHeight: '2rem',
            }}>
            {localization.get('LBL_KIOSK_FIELDS', 'SiteDesigner')}
          </Typography>
          <IconButton onClick={() => setFieldsActive(false)}>
            <ChevronLeftIcon
              sx={{
                color: `${ColorBaseGray400}`,
              }}
            />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          placeholder={localization.get(
            'LBL_KIOSK_SEARCH_FIELDS',
            'SiteDesigner'
          )}
          sx={{
            width: '15.5rem',
            height: '2.5rem',
            mt: 0,
            mb: 2.5,
            p: 0,
            '& fieldset': { border: 'none' },
          }}
          InputProps={{
            sx: {
              borderRadius: '2.75rem',
              backgroundColor: `${ColorBaseGray100}`,
            },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  alt='Fields Panel Search Icon'
                  aria-label='Fields Panel Search Icon'
                  sx={{ mr: -1 }}>
                  <SugarIcon
                    name='search-lg'
                    size='16'
                    sx={{ color: `${ColorBaseGray400}` }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            zIndex: 9000,
            maxHeight: 'calc(100vh - 7.5rem)', // TODO update once we add top Breadcrumbs to Site Designer RecordViewConfig page
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollbarColor: '#D9D9D9 #FFF',
          }}>
          <List sx={{ py: 0 }}>
            {data.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  width: '15.5rem',
                  height: '2.5rem',
                  pl: 0,
                  mb: 1.25,
                  borderRadius: '0.25rem',
                  border: `1px solid ${ColorBaseGray300}`,
                  '&:hover': {
                    border: `2px solid ${ColorBaseViolet500}`,
                    backgroundColor: `${ColorBaseViolet50}`,
                    '& .MuiSvgIcon-root': {
                      visibility: 'visible',
                    },
                    '& .MuiIcon-root': {
                      color: `${ColorBaseViolet500}`,
                    },
                    '& .MuiListItemText-primary': {
                      color: `${ColorBaseViolet700}`,
                    },
                  },
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <DragIndicatorIcon
                    fontSize='small'
                    sx={{
                      color: `${ColorBaseViolet500}`,
                      visibility: 'hidden',
                    }}
                  />
                  <SugarIcon
                    name={item.fieldType}
                    size='16'
                    tooltipText={item.tooltipText}
                    tooltipPlacement='top'
                    sx={{
                      color: `${ColorBaseGray500}`,
                      mr: 1.625,
                    }}
                  />
                  <ListItemText
                    primary={item.fieldName}
                    sx={{
                      color: `${ColorBaseGray600}`,
                      '& .MuiListItemText-primary': {
                        fontWeight: '500',
                        fontSize: '0.875rem',
                        lineHeight: '1.5rem',
                        width: '11.625rem',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default FieldsPanel;
