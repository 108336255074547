import React from 'react';
import {
  Box,
  List,
  Divider,
  ListItem,
  ListItemButton,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SugarIcon from '@kiosk/components/SugarIcon';
import {
  ColorBaseGray100,
  ColorBaseGray300,
  ColorBaseGray500,
  ColorBaseWhite,
} from '@kiosk/maple-syrup/build/color/js/sugarColorPalette';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const SUPPORT_URL = 'https://support.sugarcrm.com';
const SUGAR_URL = 'https://sugarcrm.com';

const Notch = styled('div')(({ fieldsActive }) => ({
  position: 'absolute',
  background: 'linear-gradient(8deg, #9B23EA -34.27%, #3B58CA 86.75%)',
  width: '0.25rem',
  height: '2rem',
  borderRadius: '0rem 0.25rem 0.25rem 0rem',
}));

const FieldsBackground = styled('div')(({ fieldsActive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '2rem',
  width: '2rem',
  position: 'relative',
  '&::after': {
    borderRadius: '0.25rem',
    background: 'linear-gradient(8deg, #9B23EA -34.27%, #3B58CA 86.75%)',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    height: '2rem',
    width: '2rem',
    content: '""',
    position: 'absolute',
    zIndex: 1,
    opacity: fieldsActive ? 1 : 0,
    transition: 'opacity 400ms',
  },
}));

const SiteDesignerSidebar = ({ fieldsActive, setFieldsActive }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 4.125rem)',
        width: '3.5rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: ColorBaseWhite,
        borderRight: '1px solid ' + ColorBaseGray300,
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
      }}>
      <Box
        className={
          fieldsActive
            ? 'sidebar-nav-item expanded selected'
            : 'sidebar-nav-item'
        }
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          height: '3.5rem',
          width: '3.5rem',
          marginBottom: 'auto',
          alignItems: 'center',
        }}>
        <Notch
          fieldsActive={fieldsActive}
          className={'animated-background expanded-full-fill'}
        />
        <Tooltip title='Fields' placement='right'>
          <IconButton
            disableRipple
            sx={{
              display: 'flex',
              height: '3.5rem',
              width: '3.5rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              p: 0,
              color: fieldsActive ? ColorBaseWhite : ColorBaseGray500,
              '&:hover': {
                color: ColorBaseWhite,
              },
            }}
            onClick={() => setFieldsActive(true)}>
            <FieldsBackground
              fieldsActive={fieldsActive}
              className={fieldsActive ? 'active' : ''}>
              <AccountTreeIcon
                name='fields-lg'
                sx={{
                  fontSize: 'larger',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  zIndex: 2,
                }}
              />
            </FieldsBackground>
          </IconButton>
        </Tooltip>
      </Box>
      <Divider
        sx={{
          backgroundColor: ColorBaseGray100,
          height: '0.0625rem',
          width: '3.5rem',
        }}
      />
      <List disablePadding>
        <ListItem
          className='sidebar-nav-item'
          key={'Help'}
          disablePadding
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            height: '3.5rem',
            width: '3.5rem',
            marginBottom: 'auto',
            alignItems: 'center',
            color: ColorBaseGray500,
            '&:hover': {
              color: ColorBaseWhite,
            },
          }}>
          <Notch fieldsActive={false} className='animated-background' />
          <Tooltip title='Help' placement='right'>
            <ListItemButton
              sx={{
                display: 'flex',
                height: '3.5rem',
                width: '3.5rem',
                p: 0,
              }}
              href={SUPPORT_URL}
              target='_blank'>
              <SugarIcon
                name='help-lg'
                sx={{
                  width: 'auto',
                  fontSize: 'large',
                  alignItems: 'center',
                  display: 'flex',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  justifyContent: 'center',
                }}
              />
            </ListItemButton>
          </Tooltip>
        </ListItem>
        <ListItem
          className='sidebar-nav-item'
          key={'Sugarcrm'}
          disablePadding
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            height: '3.5rem',
            width: '3.5rem',
            marginBottom: 'auto',
            alignItems: 'center',
            color: ColorBaseGray500,
            '&:hover': {
              color: ColorBaseWhite,
            },
          }}>
          <Notch fieldsActive={false} className='animated-background' />
          <Tooltip title={'SugarCRM'} placement='right'>
            <ListItemButton
              sx={{
                display: 'flex',
                height: '3.5rem',
                width: '3.5rem',
                p: 0,
              }}
              href={SUGAR_URL}
              target='_blank'>
              <SugarIcon
                name='sugar-logo-16'
                sx={{
                  fontSize: 'large',
                  alignItems: 'center',
                  display: 'flex',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  justifyContent: 'center',
                }}
              />
            </ListItemButton>
          </Tooltip>
        </ListItem>
      </List>
    </Box>
  );
};

export default SiteDesignerSidebar;
