import { useBackground } from '@kiosk/hooks/useBackground';
import { Box, Tooltip } from '@mui/material';
import SugarPattern from '@kiosk/config/images/sugar_stack_background_linear_40pct.svg';
import SugarCube from '@kiosk/config/images/sugarcrm-logo-white-cube.svg';
import { Link, useLocation } from 'react-router-dom';
import SiteDesignerTopBar from '@kiosk/components/SiteDesigner/SiteDesignerTopBar';
import SiteDesignerContent from '@kiosk/components/SiteDesigner/SiteDesignerContent';
import ModuleLayoutsContent from '@kiosk/components/SiteDesigner/ModuleLayoutsContent';

const showContent = (location) => {
  if (location.pathname.includes('module-layouts')) {
    return <ModuleLayoutsContent />;
  }
  return <SiteDesignerContent />;
};

/**
  Site Designer page
 * @returns Entry page of Site Designer
 */
const SiteDesigner = () => {
  const location = useLocation();
  useBackground('Admin', {
    backgroundImage: `url(${SugarPattern}), linear-gradient(0deg, #9B23EA 0.32%, #3B58CA 100.13%)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
  });
  return (
    <Box
      sx={{
        fontFamily: 'Inter',
        height: '100vh',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <SiteDesignerTopBar />
      {showContent(location)}
      <Link to={'https://sugarcrm.com'} target='_blank'>
        <Tooltip title={'SugarCRM'} placement='right'>
          <Box
            sx={{
              position: 'absolute',
              ml: '1.875rem',
              mb: '1.875rem',
              bottom: 0,
              left: 0,
            }}
            component='img'
            src={SugarCube}
          />
        </Tooltip>
      </Link>
    </Box>
  );
};

export default SiteDesigner;
