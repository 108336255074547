import localization from '@kiosk/i18n/localization';
import { Box, Breadcrumbs, Chip, Fab } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation } from 'react-router-dom';
import { ColorBaseWhite } from '@kiosk/maple-syrup/build/color/js/sugarColorPalette';
import { breadcrumbsDefs } from '@kiosk/metadata/AdminConfig/SideDesigner/topBarMetadata';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  return {
    height: '2.125rem',
    width: 'auto',
    paddingLeft: '0.3125rem',
    color: ColorBaseWhite,
    fontWeight: 600,
    fontSize: 16,
    fontStyle: 'normal',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)', // white at 20% opacity
      cursor: 'pointer',
    },
  };
});

const SiteDesignerTopBar = ({
  background = 'transparent',
  boxShadow = 'none',
}) => {
  const location = useLocation();
  return (
    <Box
      sx={{
        background: `${background}`,
        boxShadow: `${boxShadow}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        px: '1.25rem',
        py: '0.9375rem',
      }}>
      <Breadcrumbs sx={{ color: ColorBaseWhite }} aria-label='breadcrumb'>
        {breadcrumbsDefs.map(
          (def) =>
            location.pathname.includes(def.name) && (
              <Link key={def.name} to={def.path}>
                <StyledBreadcrumb
                  variant='outlined'
                  size='big'
                  label={localization.get(def.label, def.labelModule)}
                  icon={def.icon}
                />
              </Link>
            )
        )}
      </Breadcrumbs>
      <Link style={{ textDecoration: 'none' }} to={'/'} reloadDocument={true}>
        <Fab
          sx={{
            height: '2.25rem',
            width: '2.25rem',
            boxShadow: 'none',
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // white at 20% opacity
            color: ColorBaseWhite,
            marginLeft: 'auto',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.4)', // white at 40% opacity
            },
          }}
          aria-label='close'>
          <CloseIcon />
        </Fab>
      </Link>
    </Box>
  );
};

export default SiteDesignerTopBar;
